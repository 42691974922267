// Core React
import React from "react"
import Layout from "@components/layout/Layout/Layout"
import MyNfts from "@components/partials/MyNfts"

// Render
const MyNftsPage = () => (
  <Layout
    title="My Dragons || WhelpS NFT"
    contentClassName="px-0 max-w-full bg-secondary-whelps"
    page="mynfts"
  >
    <MyNfts />
  </Layout>
)
export default MyNftsPage
