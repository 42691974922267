import React, { useState, useEffect } from "react"
import Modal from "react-modal";
import { connect } from "react-redux"
import Carousel from "react-multi-carousel"
import { CONTRACT_ADDRESS_WHELPS, CONTRACT_ADDRESS_7THBREED } from "@util/addressHelpers"
import abi_whelps from "../ABIs/abi-whelps.json"
import abi_7thbreed from "../ABIs/abi-7thbreed.json"
import img_hashes from "./../ABIs/aggregated_hashes.json"
import { bgNames } from "@util/whelpsData"
import "react-multi-carousel/lib/styles.css"
import "./MyNfts.scss"
const https = require('https')

const responsive = {
  mobile: {
    breakpoint: { max: 10000, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

const MyNfts = ({
  web3, walletAddress, connected
}) => {
  const [tokens, setTokens] = useState([])
  const [tokensHighborn, setTokensHighborn] = useState([])

  const [modalOpen, setModalOpen] = useState(false);
  const [modalImg, setModalImg] = useState("");

  const TOKENS_POLL_TIME = 180000;

  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.9)",
      zIndex: 2000,
    },
    content: {

    },
  };

  const [traitsData, setTraitsData] = useState([])

  useEffect(() => {
    setTraitsData(tokens.map((item, idx) => ({
      ...item,
      visibleTrait: (traitsData[idx] && traitsData[idx].visibleTrait) || false,
    })).concat(
      tokensHighborn.map((item, idx) => ({
        ...item,
        visibleTrait: (traitsData[idx] && traitsData[idx].visibleTrait) || false,
      }))));
  }, [tokens, tokensHighborn]);

  useEffect(() => {
    if (connected) {
      refreshTokens();

      setInterval(() => {
        refreshTokens();
      }, TOKENS_POLL_TIME);
    }
  }, [connected]);

  const refreshTokenByIndex = async (contract, index) => {
    const tokenId = await contract.methods
    .tokenOfOwnerByIndex(walletAddress, index)
    .call();

    const metadataUrl = await contract.methods
      .tokenURIComputedName(tokenId)
      .call();

    const whelp = parseMetadata(tokenId, metadataUrl);

    setTokens(prevTokens => {
      let array = [...prevTokens];
      const _i = array.findIndex((elem) => elem.tokenId === tokenId);
      if (_i != -1) {
        array.splice(_i, 1, whelp);
      } else {
        array.push(whelp);
        array.sort((a, b) => a.tokenId - b.tokenId);
      }
      return array;
    })
  }

  const refreshTokenByIndexHighborn = async (contract, index) => {
    const tokenId = await contract.methods
    .tokenOfOwnerByIndex(walletAddress, index)
    .call();
    
    const metadataUrl = "https://whelpsio.herokuapp.com/api7-poly/" + tokenId;

    const options = {
      hostname: 'whelpsio.herokuapp.com',
      path: metadataUrl.slice(30),
      method: 'GET'
    }

    const req = https.request(options, res => {
      res.on('data', d => {
        var jsonObject = JSON.parse(d);

        const ages = ["Egg", "Baby", "Whelp", "Young adult", "Adult"];

        let age = 0;
        if (jsonObject["attributes"].find((a) => a["trait_type"] == "Horns")) age = 4;
        else if (jsonObject["attributes"].find((a) => a["trait_type"] == "Breath")) age = 3;
        else if (jsonObject["attributes"].find((a) => a["trait_type"] == "Body Patterns")) age = 2;
        else if (jsonObject["attributes"].find((a) => a["trait_type"] == "Color")) age = 1;
        else if (jsonObject["attributes"].find((a) => a["trait_type"] == "Background")) age = 0;

        const walletImg = []
        for (let i = 0; i < jsonObject["past_evolutions"].length; i++) {
          walletImg.push({
            traitsImg: "https://whelps.mypinata.cloud/ipfs/" + jsonObject["past_evolutions"][i],
            traitsType: ages[i],
          })
        }
        walletImg.push({
          traitsImg: jsonObject['image'],
          traitsType: ages[age],
        })

        walletImg.reverse();

        const whelp = {
          walletImg:  walletImg,
          currentEvolution: ages[age],
          traitsDetails: jsonObject["attributes"],
          tokenId,
          type: "HIGHBORN"
        }

        if (whelp.currentEvolution !== "Adult") {
          let next = jsonObject["birth_timestamp"] + 3*24*3600;
          while (next < Date.now()/1000) {
            next += 5*24*3600;
          }
          whelp['nextEvolution'] = ages[age+1];
          whelp['nextEvolutionTimestamp'] = next;
        }    

        setTokensHighborn(prevTokens => {
          let array = [...prevTokens];
          const _i = array.findIndex((elem) => elem.tokenId === tokenId);
          if (_i != -1) {
            array.splice(_i, 1, whelp);
          } else {
            array.push(whelp);
            array.sort((a, b) => a.tokenId - b.tokenId);
          }
          return array;
        })
      })
    })
    
    req.on('error', error => {
      console.error(error)
    })
    
    req.end()
  }

  const refreshTokens = async () => {
    console.log('debug', 1111111111)
    if (
      !connected ||
      (await web3.eth.getCode(CONTRACT_ADDRESS_WHELPS)) == "0x" ||
      (await web3.eth.getCode(CONTRACT_ADDRESS_7THBREED)) == "0x"
    ) {
      return
    }

    const contract = new web3.eth.Contract(
      abi_whelps,
      CONTRACT_ADDRESS_WHELPS
    )

    const contractHighborn = new web3.eth.Contract(
      abi_7thbreed,
      CONTRACT_ADDRESS_7THBREED
    )

    const noTokens = await contract.methods
      .balanceOf(walletAddress)
      .call();


    for (let i = 0; i < noTokens; i++) {
      refreshTokenByIndex(contract, i);
    }

    // Highborn 

    const noTokensHighborn = await contractHighborn.methods
      .balanceOf(walletAddress)
      .call();

    for (let i = 0; i < noTokensHighborn; i++) {
      refreshTokenByIndexHighborn(contractHighborn, i);
    }
  }

  const noTraitsToState = (no) => {
    switch (no) {
      case 2: return "Egg";
      case 3: return "Baby";
      case 4: return "Whelp";
      case 5: return "Young Adult";
      case 6: return "Adult";
    }
    return "";
  }

  const parseMetadata = (tokenId, metadataUrl) => {
    const parts = metadataUrl.split('-');

    const walletImg = []

    const subset = [parts[0]]
    for (let i = 1; i < parts.length; i++) {
      subset.push(parts[i]);

      walletImg.push({
        traitsImg: "https://whelps.mypinata.cloud/ipfs/" + img_hashes[subset.join('-')],
        traitsType: noTraitsToState(subset.length),
      })
    }

    walletImg.reverse();

    const whelp = {
      walletImg,
      currentEvolution: noTraitsToState(parts.length),
      traitsDetails: parts,
      tokenId
    }

    if (whelp.currentEvolution !== "Adult") {
      whelp['nextEvolution'] = noTraitsToState(parts.length+1);
    }

    return whelp;
  }

  const generateNextEvolutionHighborn = (nextTimestamp) => {
    let remainingSeconds = Math.max(0, nextTimestamp - Math.floor(Date.now()/1000));
    const minutes = Math.floor(remainingSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 15) {
      return `Evolving...`;
    } else if (minutes < 60) {
      return `Under an hour`;
    } else if (hours < 24) {
      return `${hours} hours`;
    } else {
      return `${days} days`;
    }
  }

  const flipTraits = index => {
    setTraitsData(
      traitsData.map((t, idx) => {
        if (idx === index) {
          return {
            ...t,
            visibleTrait: !t.visibleTrait,
          }
        } else {
          return t
        }
      })
    )
  }

  const handleNone = (original, comparable) => {
    if (original == comparable) {
      return 'NONE'
    }
    return original
  }

  const closeModal = ()  =>{
    setModalOpen(false);
    setModalImg("");
  }

  const openModal = (img) => {
    setModalImg(img);
    setModalOpen(true);
  }

  return (
    <>
      <div className="py-8 bg-secondary-whelps md:py-10 ">
        <div className="container mx-auto">
          <div className="my-5 text-2xl tracking-wider text-center font-lemon text-primary-whelps md:text-4xl">
            My Dragons
          </div>
          <div className="w-full mb-5 tracking-wider text-center text-white font-roboto-light text-md">
            While evolution occurs approximately every 30 hours, hatching is an imprecise science. Please allow up to 15 extra minutes.
          </div>

          <div className={`${ traitsData.length === 1 ? "flex justify-center" : "grid grid-cols-1 gap-5 text-center dragons-lists md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-12" }`}>
            {traitsData.map((dt, idx) => (
              <div className="dragons-list-item" key={idx}>
                <div className="max-w-xs mx-auto md:max-w-md">
                  <div className="relative text-center img-box">
                    <div className="relative">
                      <Carousel
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        infinite={true}
                        className="traits-carousel"
                      >
                        {dt.walletImg.map((item, idx) => (
                          <div key={idx}>
                            <img
                              src={item.traitsImg}
                              alt="My Dragons"
                              className="w-full mx-auto"
                              onClick={() => openModal(item.traitsImg)}
                            />
                            <div className="mt-4 mb-2 text-xl tracking-wider text-center text-white uppercase font-roboto-bold">
                              {item.traitsType}
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <div className="absolute pb-3 transform -translate-x-1/2 bottom-14 left-1/2">
                      <div
                        className="px-4 pt-1 text-sm uppercase rounded-full cursor-pointer font-roboto-bold bg-primary-whelps"
                        onClick={() => flipTraits(idx)}
                      >
                        View Traits
                      </div>
                    </div>
                    <div
                      className={`absolute top-0 p-2 text-center w-full z-full ${
                        !dt.visibleTrait && "hidden"
                      }`}
                      style={{ backgroundColor: "rgba(0,0,0,0.8)", height: 'calc( 100% - 52px )' }}
                    >
                      <div className="relative">
                        <img
                          src="/arrows/icon-close.png"
                          alt="Arrow Image"
                          className="absolute cursor-pointer right-2 top-2"
                          width="28"
                          onClick={() => flipTraits(idx)}
                        />
                        <div className="pt-8 text-xl tracking-wider text-center font-lemon text-primary-whelps">
                          Traits
                        </div>
                        { dt.type == "HIGHBORN" ?
                          <div className="text-base uppercase text-light-whelps font-roboto-bold">
                            {["Background", "Object", "Body Features", "Color", "Body Patterns", "Moods", "Breath", "Claws", "Horns", "Tail"].map((attr) =>
                              <span className="block mt-0.5">
                                {attr}: {dt.traitsDetails.find((a) => a["trait_type"] == attr) ? dt.traitsDetails.find((a) => a["trait_type"] == attr)["value"] : "-"}
                              </span>
                            )}
                          </div>
                        : <div className="text-base uppercase text-light-whelps font-roboto-bold">
                            <span className="block mt-3">
                              Breed: {dt.traitsDetails.length > 0 ? dt.traitsDetails[0] : "-"}
                            </span>
                            <span className="block mt-2">
                              Background:{" "}
                              {dt.traitsDetails.length > 1 ? bgNames[dt.traitsDetails[0]][dt.traitsDetails[1]] : "-"}
                            </span>
                            <span className="block mt-2">
                              Pattern: {dt.traitsDetails.length > 2 ? handleNone(dt.traitsDetails[2],"nopattern") : "-"}
                            </span>
                            <span className="block mt-2">
                              Expression:{" "}
                              {dt.traitsDetails.length > 3 ? handleNone(dt.traitsDetails[3],"nomood") : "-"}
                            </span>
                            <span className="block mt-2">
                              Breath: {dt.traitsDetails.length > 4 ? handleNone(dt.traitsDetails[4],"nobreath") : "-"}
                            </span>
                            <span className="block mt-2">
                              Horns: {dt.traitsDetails.length > 5 ? handleNone(dt.traitsDetails[5],"nohorns") : "-"}
                            </span>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-between mt-4 mb-2 text-xl tracking-wider text-center text-white uppercase font-roboto-bold">
                    <span>
                      <img
                        src="/arrows/icon-previous.png"
                        alt="Arrow Image"
                        className="inline mx-2"
                        width="28"
                      />
                    </span>
                    <span className="mx-5">{dt.currentEvolution}</span>
                    <span>
                      <img
                        src="/arrows/icon-next.png"
                        alt="Arrow Image"
                        className="inline mx-2"
                        width="28"
                      />
                    </span>
                  </div> */}
                  {!!dt.nextEvolution ? (
                    <div className="w-full mt-3 tracking-wider text-center text-white font-roboto-light text-md">
                      Next Evolution:{" "}
                      <span className="font-roboto-bold">{generateNextEvolutionHighborn(dt.nextEvolutionTimestamp)}</span>
                    </div>
                  ) : (
                    <div className="w-full mt-3 tracking-wider text-center text-white font-roboto-light text-md">
                      Final Form
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        style={modalStyles}
        contentLabel="Modal"
        onRequestClose={() => closeModal()}
        className="relative w-full h-auto max-w-xl p-5 mx-auto transform bg-black border-2 border-primary-dark border-whelps rounded-xl top-2/4 md:max-w-xl -translate-y-2/4"
      >
        <div className="font-medium text-left text-white line-height-medium">
          <img src={modalImg} className="w-full mx-auto rounded-xl"/>
        </div>
      </Modal>
    </>
  )
}

const stateProps = (state) => ({
  web3: state.web3,
  walletAddress: state.walletAddress,
  connected: state.connected
});

export default connect(stateProps, null)(MyNfts);